import React from 'react'
import '../../styles/overrides.scss'
import "bootstrap/dist/js/bootstrap.min.js"
import "@popperjs/core/dist/umd/popper.min.js"
import 'bootstrap-icons/font/bootstrap-icons.scss'
import '../../styles/global.scss'
import Header from './header'
import Footer from './footer'

const Page = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  )
}

export default Page