import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => {
  return (
		<header>
			<div className='navbar navbar-expand-lg navbar-light py-2'>
				<div className='container-xxl'>
					<div>
						<a href="/" className="navbar-brand fw-bold">
							<StaticImage 
								src='../../images/devine-ride-logo.png' 
								alt='Devine Ride Logo'
								placeholder="blurred"
                width={160}
							/>
						</a>
					</div>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarcontent" aria-controls="navbarcontent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarcontent">
						<div className="d-lg-flex justify-content-between w-100">
							<ul className="navbar-nav">
							<li className="nav-item">
									<a className="nav-link text-dark" href="/reservation">Reservations</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-dark" href="/about">About</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-dark" href="/vehicles">Vehicles</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-dark" href="/faqs">FAQs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-dark" href="/faqs#rates">Rates</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-dark" href="/contact">Contact</a>
								</li>
							</ul>
							<ul className="navbar-nav">
								<li className="nav-item">
									<a className="nav-link text-dark" href="/">(949) 355-8820</a>
								</li>
								<li className="nav-item">
									<a className="btn btn-dark" href="/quote" role="button">Free quote</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
  )
}

export default Header