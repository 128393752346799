import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-dark py-3 text-light'>
      <div className='container-xxl'>
        <div className='d-md-flex justify-content-between align-items-center'>
          <p className='m-0'>Copyright &copy; {new Date().getFullYear()} Devine Ride. All rights reserved.</p>
          <div>
            <a href="https://facebook.com/devineridelimo" className='text-light text-decoration-none border-bottom'>Facebook</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer